<template>
  <div class="">
    <DashboardNavbar />
    <div class="app-content">
      <div class="reports-cards">
        <div
          v-if="checkPrivilege(hasCategory())"
          class="reports-card animate__animated animate__bounceInRight"
        >
          <router-link :to="{ name: 'Categories' }" class="">
            <img src="@/assets/images/categories.svg" />{{
              $t("Categories.modelName")
            }}
          </router-link>
        </div>
        <div
          v-if="checkPrivilege(hasProduct())"
          class="reports-card animate__animated animate__bounceInUp"
        >
          <router-link :to="{ name: 'Products' }" class="">
            <img src="@/assets/images/products.svg" />{{
              $t("Products.modelName")
            }}
          </router-link>
        </div>
        <div
          v-if="checkPrivilege(hasEducationalStage())"
          class="reports-card animate__animated animate__bounceInLeft"
        >
          <router-link :to="{ name: 'EducationalStages' }" class="">
            <img src="@/assets/images/educationalStages.svg" />{{
              $t("EducationalStages.modelName")
            }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DashboardNavbar from "./../../../layouts/components/DashboardNavbar.vue";
import { checkPrivilege } from "@/utils/functions";
import {
  hasCategory,
  hasProduct,
  hasEducationalStage,
} from "@/utils/privilegeHelper";

export default {
  components: { DashboardNavbar },
  data() {
    return {};
  },
  methods: {
    checkPrivilege,
    hasCategory,
    hasProduct,
    hasEducationalStage,
  },
  computed: {
    ...mapGetters(["usersPrivilegeData"]),
  },
};
</script>
